import { _ } from "@bazo/js-translator";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { logOut } from "../actions";
import { Profile, User } from "../api/types";
import paths from "../app/paths";
import { RootState } from "../app/rootReducer";
import { formatCurrency } from "../utils";

interface Props {
	user: User;
	profile: Profile;
}

export const Navbar = ({ user, profile }: Props) => {
	return (
		<header>
			<nav className="container">
				<div>
					<Link className="link" to={paths.HOME.toURL()}>
						<img className="logo" src="/logo.png" alt="Ulož doklad" />
					</Link>
				</div>
				{user && (
					<div className="links">
						<span className="user-name">{user.displayName}</span>
						{profile && (
							<span className="pill pill-inverse">
								{formatCurrency(profile.totalReceiptsSum)} ({profile.totalReceiptsCount})
							</span>
						)}
						<span className="link" onClick={logOut}>
							{_("navbar.logOut")}
						</span>
					</div>
				)}
			</nav>
		</header>
	);
};

const ConnectedNavbar = () => {
	const user = useSelector<RootState, User>((state) => state.auth.user);
	const profile = useSelector<RootState, Profile>((state: RootState) => state.profile);

	return <Navbar user={user} profile={profile} />;
};

export default ConnectedNavbar;
