import React from "react";
import { Switch } from "react-router-dom";

import paths from "../app/paths";
import Login from "../pages/login";
import Receipts from "../pages/receipts";
import Register from "../pages/register";
import { PrivateRoute } from "./securedComponent";
import WithLayout from "./withLayout";

const Routes = () => {
	return (
		<Switch>
			<WithLayout path={paths.LOGIN.path} exact component={Login} />
			<WithLayout path={paths.REGISTER.path} exact component={Register} />
			<WithLayout path={paths.HOME.path} exact component={PrivateRoute(Receipts)} />
		</Switch>
	);
};

export default Routes;
