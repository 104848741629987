import { Translator } from "@bazo/js-translator";
import { Store } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { RootState } from "./app/rootReducer";

export interface CustomWindow extends Window {
	store?: Store<RootState>;
	_?: Translator["translate"];
	loadLang?: (lang: string) => Promise<void>;
}

export type Connected<F extends (...args: any) => any> = (...params: Parameters<F>) => ReturnType<ReturnType<F>>;
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>;
export type MyThunkDispatch = ThunkDispatch<RootState, undefined, any>;

export enum Lang {
	SK = "sk",
	EN = "en",
}
