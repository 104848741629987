import { connect, InferableComponentEnhancerWithProps } from "react-redux";

const withRedux = <StateProps extends {} = {}, DispatchProps extends {} = {}, OwnProps extends {} = {}>(
	actionCreators = {},
	mapStateToProps = null,
	options: object = {}
): InferableComponentEnhancerWithProps<StateProps & DispatchProps, OwnProps> => {
	return connect<ReturnType<typeof mapStateToProps>, typeof actionCreators, OwnProps>(
		mapStateToProps,
		actionCreators,
		undefined,
		options
	);
};

export default withRedux;
