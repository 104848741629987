import "../../styles/rippleLoader.scss";

import React, { ReactElement } from "react";

import { LoaderProps } from "./types";

function RippleLoader({ text, loading = true, notext = false }: LoaderProps): ReactElement {
	if (!loading) {
		return null;
	}

	return (
		<>
			<div className="lds-ripple">
				<div></div>
				<div></div>
			</div>
			<div className="modal-backdrop fade show" />
		</>
	);
}

export default RippleLoader;
