import React, { ReactElement } from "react";

import RingLoader from "./ringLoader";
import RippleLoader from "./rippleLoader";
import { LoaderProps, LoaderType } from "./types";

function Loader({ text, loading = true, notext = false, type = LoaderType.RING }: LoaderProps): ReactElement {
	if (!loading) {
		return null;
	}

	switch (type) {
		case LoaderType.RIPPLE: {
			return <RippleLoader text={text} loading={loading} notext={notext} />;
		}

		case LoaderType.RING: {
			return <RingLoader text={text} loading={loading} notext={notext} />;
		}
	}
}

export default Loader;
