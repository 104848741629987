/* eslint-disable */
import { HttpClient } from "@bazo/fetch-client";
import Cookies from "js-cookie";

import { logOut } from "../actions";

const onRequestCreate = async (request: Request): Promise<any> => {
	const accessToken = Cookies.get("accessToken");

	if (accessToken) {
		request.headers.append("Authorization", `Bearer ${accessToken}`);
	}
};

function createHttp(baseURL: string): HttpClient {
	const client = new HttpClient(baseURL);
	client.on(HttpClient.REQUEST_CREATE, onRequestCreate);

	//create-react-app can't compile this yet
	client.on(HttpClient.RESPONSE_ERROR, ([res, ctx] /*: [res: Response, ctx: { throw: boolean }]*/) => {
		ctx.throw = false;
		if (res.status === 401 || res.status === 403) {
			logOut();
		}
	});

	return client;
}

const baseUrl = process.env.REACT_APP_BASE_API_URL;
export const createApiClient = (): HttpClient => createHttp(baseUrl);
