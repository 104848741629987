import "./styles/app.scss";
import "@fortawesome/fontawesome-free/css/all.css";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// Add the Firebase products that you want to use
import "firebase/auth";

import { Translator } from "@bazo/js-translator";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as firebase from "firebase/app";
import { createBrowserHistory } from "history";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { name, version } from "./../package.json";
import App from "./app/app";
import createStore from "./app/createStore";
import FirebaseContext from "./contexts/firebaseContext";
import * as serviceWorker from "./serviceWorker";
import { CustomWindow, Lang } from "./types";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
		environment: "prod",
		release: `${name}@${version}`,
	});

	LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
	setupLogRocketReact(LogRocket);

	LogRocket.getSessionURL((sessionURL) => {
		Sentry.configureScope((scope) => {
			scope.setExtra("sessionURL", sessionURL);
		});
	});
}

const history = createBrowserHistory({
	basename: process.env.REACT_APP_BASE_PATH,
});
const { store, persistor } = createStore(history);

const myWindow: CustomWindow = window;

const lang = Lang.SK;

const translator = new Translator(lang);

Translator.register(window, translator);

myWindow.store = store;

myWindow.loadLang = async (lang: string): Promise<void> => {
	//https://wanago.io/2018/08/20/webpack-4-course-part-eight-dynamic-imports-with-prefetch-and-preload/
	const translations = await import(`./locales/${lang}.json` /* webpackPreload: true */);
	translator.load(lang, translations.default);
};

myWindow.loadLang(lang).then(() => {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<FirebaseContext.Provider value={firebase}>
					<App history={history} />
				</FirebaseContext.Provider>
				,
			</PersistGate>
		</Provider>,

		document.getElementById("root")
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
