import { format } from "date-fns";
import React from "react";

import { Receipt } from "../../api/types";
import QRCode from "./qrCode";

const ReceiptPreview = ({ receipt, showEmpty = false }: { receipt: Receipt; showEmpty?: boolean }) => {
	if (!receipt) {
		return (
			showEmpty && (
				<div className="receipt-preview empty">
					<div className="receipt">
						<img src="/emptyStateIcon.svg" alt="empty state" />
					</div>
				</div>
			)
		);
	}
	return (
		<div className="receipt-preview">
			<div className="receipt">
				<div className="company">
					<strong>{receipt.organization.name}</strong> <br />
					{receipt.organization.streetName} {receipt.organization.propertyRegistrationNumber},{" "}
					{receipt.organization.postalCode} {receipt.organization.municipality} <br />
					{receipt.unit.streetName} {receipt.unit.buildingNumber}, {receipt.unit.postalCode}{" "}
					{receipt.unit.municipality} <br />
					DIČ: {receipt.organization.taxID} IČ DPH: {receipt.organization.vatID} IČO:{" "}
					{receipt.organization.companyID}
				</div>
				<div className="time-and-block-number">
					<strong>{format(receipt.issueTimestamp * 1000, "dd.MM.yyyy HH:mm:ss")}</strong> č. bloku:{" "}
					{receipt.receiptNumber}
				</div>
				<div className="items">
					<table>
						<thead>
							<tr>
								<td></td>
								<td></td>
								<td className="price">EUR</td>
							</tr>
						</thead>
						<tbody>
							{receipt.items.map((item, i) => {
								return (
									<tr key={i}>
										<td>{item.name}</td>
										<td className="quantity">
											{item.quantity}
											{item.quantity !== 1 ? ` * ${(item.price / item.quantity).toFixed(2)}` : ""}
										</td>
										<td className="price">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={2}>SPOLU</td>
								<td className="price">
									<strong>{receipt.totalPrice}</strong>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div className="tax">
					<table>
						<thead>
							<tr>
								<td>Daň</td>
								<td className="price">Základ</td>
								<td className="price">Suma</td>
							</tr>
						</thead>
						<tbody>
							{receipt.taxBaseBasic > 0 && (
								<tr>
									<td>{receipt.vatRateBasic}</td>
									<td className="price">{receipt.taxBaseBasic}</td>
									<td className="price">{receipt.vatAmountBasic}</td>
								</tr>
							)}
							{receipt.taxBaseReduced > 0 && (
								<tr>
									<td>{receipt.vatRateReduced}</td>
									<td className="price">{receipt.taxBaseReduced}</td>
									<td className="price">{receipt.vatAmountReduced}</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								<td>SPOLU</td>
								<td className="price">{receipt.taxBaseBasic + receipt.taxBaseReduced}</td>
								<td className="price">{receipt.vatAmountBasic + receipt.vatAmountReduced}</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div>
					Kód pokladne: <strong>{receipt.cashRegisterCode}</strong>
					<br />
					OKP: <span className="okp">{receipt.okp}</span>
					<br />
					UID: <span className="uid">{receipt.ID}</span>
				</div>
				<div className="qr">
					<QRCode content={receipt.ID} />
				</div>
			</div>
		</div>
	);
};

export default ReceiptPreview;
