import { _ } from "@bazo/js-translator";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchProfile } from "../actions";
import { getReceipts, getReportTotal } from "../api";
import { Receipt, TotalResponse, User } from "../api/types";
import Loader from "../components/loaders";
import AddReceiptForm from "../features/receipts/addReceiptForm";
import EmptyState from "../features/receipts/emptyState";
import ReceiptPreview from "../features/receipts/receiptPreview";
import ReceiptsFilter, { dateFactory, ReceiptFilterOption } from "../features/receipts/receiptsFilter";
import ReceiptsTable from "../features/receipts/receiptsTable";
import { formatCurrency } from "../utils";

const defaultDate = dateFactory(ReceiptFilterOption.THIS_MONTH);

const limit = 10;

const Receipts = (props) => {
	const user = props.user as User;

	const dispatch = useDispatch();
	const [receipts, setReceipts] = useState<Receipt[]>([]);
	const [receipt, selectReceipt] = useState<Receipt>(null);
	const [isLoading, setLoading] = useState(true);
	const [total, setTotal] = useState<TotalResponse>({ count: 0, total: 0 });
	const [startAfter, setStartAfter] = useState(null);
	const [{ from, to, option }, setDates] = useState({ ...defaultDate, option: ReceiptFilterOption.THIS_MONTH });
	const [scannerOpen, setScannerOpen] = useState(false);

	const openScanner = () => {
		setScannerOpen(true);
	};

	const closeScanner = () => {
		setScannerOpen(false);
	};

	const load = useCallback(async () => {
		const page = await getReceipts(from, to, limit, startAfter);
		setReceipts([...receipts, ...page.receipts]);
		if (page.count === page.limit) {
			setStartAfter(page.startAfter);
		}
		setLoading(false);
	}, [from, to, startAfter]);

	const getTotal = () => {
		getReportTotal(from, to).then(setTotal);
	};

	useEffect(() => {
		if (startAfter) {
			load();
		}
	}, [load, startAfter]);

	useEffect(() => {
		getTotal();
		load();
	}, [from, load, to]);

	const reload = () => {
		setLoading(true);
		load();
		dispatch(fetchProfile());
	};

	const onFilterChange = (from: Date, to: Date, option: ReceiptFilterOption) => {
		setReceipts([]);
		setStartAfter(null);
		setDates({ from, to, option });
	};

	const onScanned = (receipt: Receipt) => {
		setScannerOpen(false);
		reload();
	};

	return (
		<>
			<ReceiptsFilter onChange={onFilterChange} from={from} to={to} />
			<div className="clearfix receipts">
				{isLoading ? (
					<Loader />
				) : (
					<>
						<div className="float-left width-50">
							<div className="filter-info">
								<span className="selected-filter">
									{option === ReceiptFilterOption.CUSTOM
										? `${format(from, "dd. MMMM yyyy")} - ${format(to, "dd. MMMM yyyy")}`
										: /*_(`receiptsFilter.${option}`)*/ ""}
								</span>
								<span>
									<span className="filter-total pill">
										{formatCurrency(total.total)} ({total.count})
									</span>
									&nbsp;
									<span className="pill link add-receipt-btn" onClick={openScanner}>
										<i></i>&nbsp;
										{_("receipts.addReceipt")}
									</span>
								</span>
							</div>

							{receipts.length > 0 ? (
								<ReceiptsTable receipts={receipts} onRowSelect={selectReceipt} />
							) : (
								<EmptyState />
							)}
						</div>
						<div className="float-right width-50">
							<ReceiptPreview receipt={receipt} />
						</div>
					</>
				)}
			</div>
			{scannerOpen && <AddReceiptForm onCloseClicked={closeScanner} onScanned={onScanned} user={user} />}
		</>
	);
};

export default Receipts;
