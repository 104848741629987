import "../../styles/ringLoader.scss";

import { _ } from "@bazo/js-translator";
import React, { ReactElement } from "react";

import { LoaderProps } from "./types";

function RingLoader({ text, loading = true, notext = false }: LoaderProps): ReactElement {
	if (!loading) {
		return null;
	}

	return (
		<>
			<div className="loading">
				<div className="loader">
					<div className="lds-ring">
						<div />
						<div />
						<div />
						<div />
					</div>
					{!notext ? (
						<div className="label">{typeof text === "string" ? text : _("loader.defaultText")}</div>
					) : null}
				</div>
			</div>
			<div className="modal-backdrop fade show" />
		</>
	);
}

export default RingLoader;
