import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Profile } from "../../api/types";

const initialState = null as Profile;

export type ProfileState = typeof initialState;

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		updateProfile(state, action: PayloadAction<Profile>) {
			return action.payload;
		},
		clearProfile() {
			return initialState;
		},
	},
});

export const { updateProfile, clearProfile } = profileSlice.actions;

export default profileSlice.reducer;
