import { CellRenderer, DataGrid } from "@bazo/datagrid";
import { format, parseISO } from "date-fns";
import React from "react";

import { Receipt, Shop } from "../../api/types";
import ShopIcon from "../../components/shopIcon";
import { formatCurrency } from "../../utils";

const dateRenderer: CellRenderer<string, Receipt> = (dateString) => {
	const date = parseISO(dateString);
	return <span>{format(date, "dd. MMMM yyyy HH:mm")}</span>;
};

const totalRenderer: CellRenderer<number, Receipt> = (total, row) => {
	const number = formatCurrency(total);
	return (
		<span className="total">
			{number} <br />({row.items.length})
		</span>
	);
};

const shopRenderer: CellRenderer<Shop, Receipt> = (shop: Shop, row: Receipt) => {
	return (
		<div className="shop clearfix">
			<div className="shop-icon float-left">
				<ShopIcon icon={shop.icon} />
			</div>
			<div className="shopping-data">
				<span className="shop-name">{shop.name}</span> <br />
				<span className="issue-date">{dateRenderer(row.issueDate)}</span>
			</div>
		</div>
	);
};

const ReceiptsTable = ({ receipts, onRowSelect }: { receipts: Receipt[]; onRowSelect: (row: Receipt) => void }) => {
	return (
		<DataGrid
			className="receipts-table"
			data={receipts}
			columns={{
				shop: "Shop",
				totalPrice: "Total",
			}}
			renderers={{
				shop: shopRenderer,
				totalPrice: totalRenderer,
			}}
			onRowClick={onRowSelect}
			preselectedRow={0}
		/>
	);
};

export default ReceiptsTable;
