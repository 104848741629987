import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { MapStateToProps } from "react-redux";

import { fetchProfile } from "../actions";
import { User } from "../api/types";
import Routes from "../components/routes";
import { Connected } from "../types";
import withRedux from "../withRedux";
import { RootState } from "./rootReducer";

interface StateProps {
	user: User;
	isAuthenticated: boolean;
}

interface DispatchProps {
	fetchProfile: Connected<typeof fetchProfile>;
}

interface OwnProps {
	history: History;
}

type Props = StateProps & DispatchProps & OwnProps;

const App = ({ history, user, isAuthenticated, fetchProfile }: Props) => {
	useEffect(() => {
		if (user && isAuthenticated) {
			fetchProfile();
		}
	}, [user, isAuthenticated, fetchProfile]);

	return (
		<ConnectedRouter history={history}>
			<Routes />
		</ConnectedRouter>
	);
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
	{ auth }: RootState,
	{ history }: OwnProps
) => {
	return { ...auth, history };
};

export default withRedux<StateProps, DispatchProps, OwnProps>({ fetchProfile }, mapStateToProps)(App);
