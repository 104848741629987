import "../styles/login.scss";

import { _ } from "@bazo/js-translator";
import React, { useContext } from "react";
import { RouteChildrenProps } from "react-router-dom";

import { signIn } from "../api";
import paths from "../app/paths";
import FirebaseContext from "../contexts/firebaseContext";

const Login = ({ history }: RouteChildrenProps<any>) => {
	const firebase = useContext(FirebaseContext);

	const onFacebookLogin = () => {
		const provider = new firebase.auth.FacebookAuthProvider();
		login(provider);
	};

	const onGoogleLogin = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		login(provider);
	};

	const login = (provider: firebase.auth.AuthProvider) => {
		firebase.auth();
		//.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(async ({ additionalUserInfo, user }) => {
				if (additionalUserInfo.isNewUser) {
					await signIn(user.email, user.uid);
				}

				history.push(paths.HOME.toURL());
			})
			.catch((error) => {
				console.log(error);
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				const credential = error.credential;
				// ...
			});
	};

	return (
		<div>
			<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet" />
			{/*
			<span className="btn btn-block btn-primary" onClick={onFacebookLogin}>
				<i className="fab fa-facebook mr-2"></i> Sign in using Facebook
			</span>
			*/}
			<div className="button button-login login-with-google" onClick={onGoogleLogin}>
				<img
					src="/btn_google_light_normal_ios.svg"
					alt={_("login.signInWith", null, { method: "Google+" })}
				></img>
				<span>{_("login.signInWith", null, { method: "Google+" })}</span>
			</div>
		</div>
	);
};

export default Login;
