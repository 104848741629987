import "react-datepicker/src/stylesheets/datepicker.scss";

import { _ } from "@bazo/js-translator";
import clsx from "clsx";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Field, Form } from "react-final-form";

const emptyDatePickerState = { from: null as Date, to: null as Date };
type DatePickerState = typeof emptyDatePickerState;

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }: any) => {
	const [state, setState] = useState(value);

	useEffect(() => {
		onChange(state);
	}, [onChange, state]);

	const changeFrom = (from: Date) => {
		setState({ ...state, from });
		onChange(state);
	};

	const changeTo = (to: Date) => {
		setState({ ...state, to });
	};

	const popperPlacement = "bottom-start";

	return (
		<div className="datepicker">
			<DatePicker
				selected={state.from}
				onChange={changeFrom}
				timeIntervals={15}
				timeCaption="time"
				dateFormat="dd.MM.yyyy"
				className={`datepicker-field datepicker-from ${rest.className}`}
				startDate={state.from}
				endDate={state.to}
				popperPlacement={popperPlacement}
			/>
			<DatePicker
				selected={state.to}
				onChange={changeTo}
				timeIntervals={15}
				timeCaption="time"
				dateFormat="dd.MM.yyyy"
				className={`datepicker-field datepicker-to ${rest.className}`}
				minDate={state.from}
				startDate={state.from}
				endDate={state.to}
				popperPlacement={popperPlacement}
			/>
		</div>
	);
};
export enum ReceiptFilterOption {
	THIS_MONTH = "thisMonth",
	LAST_MONTH = "lastMonth",
	ALL = "all",
	CUSTOM = "custom",
}

export function dateFactory(option: ReceiptFilterOption): { from: Date; to: Date } {
	let from: Date = null;
	let to: Date = null;
	switch (option) {
		case ReceiptFilterOption.THIS_MONTH: {
			const now = new Date();
			from = startOfMonth(now);
			to = endOfMonth(now);
			break;
		}

		case ReceiptFilterOption.LAST_MONTH: {
			const lastMonth = subMonths(new Date(), 1);
			from = startOfMonth(lastMonth);
			to = endOfMonth(lastMonth);
			break;
		}

		case ReceiptFilterOption.ALL: {
			from = new Date("2000-01-01");
			to = new Date();
			break;
		}
	}

	return { from, to };
}

type OnReceiptFilterChange = (from: Date, to: Date, option: ReceiptFilterOption) => void;

const ReceiptsFilter = ({ onChange, from, to }: { onChange: OnReceiptFilterChange; from: Date; to: Date }) => {
	const [selected, setSelected] = useState(ReceiptFilterOption.THIS_MONTH);
	const [showForm, setShowForm] = useState(false);
	const node = useRef();

	const handleChange = (option: ReceiptFilterOption): void => {
		setSelected(option);

		if (option !== ReceiptFilterOption.CUSTOM) {
			setShowForm(false);
			const { from, to } = dateFactory(option);
			onChange(from, to, option);
		} else {
			setShowForm(!showForm);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		//@ts-ignore
		if (showForm !== null) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showForm]);

	//https://codesandbox.io/s/9o3lw5792w
	const handleClickOutside = (e) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		//@ts-ignore
		if (node.current?.contains(e.target)) {
			// inside click
			return;
		}
		setShowForm(false);
	};

	const onSubmit = (values): void => {
		const { from, to } = values.issueDate as DatePickerState;
		setShowForm(false);
		onChange(from, to, selected);
	};

	return (
		<div className="receipts-filter">
			<span
				onClick={handleChange.bind(null, ReceiptFilterOption.THIS_MONTH)}
				className={clsx("receipt-filter-item", { active: selected === ReceiptFilterOption.THIS_MONTH })}
			>
				{_("receiptsFilter.thisMonth")}
			</span>
			&nbsp;
			<span
				onClick={handleChange.bind(null, ReceiptFilterOption.LAST_MONTH)}
				className={clsx("receipt-filter-item", { active: selected === ReceiptFilterOption.LAST_MONTH })}
			>
				{_("receiptsFilter.lastMonth")}
			</span>
			&nbsp;
			<span
				onClick={handleChange.bind(null, ReceiptFilterOption.ALL)}
				className={clsx("receipt-filter-item", { active: selected === ReceiptFilterOption.ALL })}
			>
				{_("receiptsFilter.all")}
			</span>
			&nbsp;
			<span
				onClick={handleChange.bind(null, ReceiptFilterOption.CUSTOM)}
				className={clsx("receipt-filter-item", { active: selected === ReceiptFilterOption.CUSTOM })}
			>
				{_("receiptsFilter.custom")}
			</span>
			{showForm && (
				<div className="custom-date dropdown" ref={node}>
					<Form
						onSubmit={onSubmit}
						initialValues={{ issueDate: { from, to } }}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<form onSubmit={handleSubmit}>
								<div className="form-row">
									<Field<Date>
										name="issueDate"
										component={DatePickerAdapter}
										className="form-control"
									/>
									<button className="button-search" type="submit" disabled={submitting || pristine}>
										<i className="fas fa-search"></i>
									</button>
								</div>
							</form>
						)}
					/>
				</div>
			)}
		</div>
	);
};

export default ReceiptsFilter;
