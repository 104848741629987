export enum LoaderType {
	RING = "ring",
	RIPPLE = "ripple",
}

export interface LoaderProps {
	text?: string;
	loading?: boolean;
	notext?: boolean;
	type?: LoaderType;
}
