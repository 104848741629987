import { push } from "connected-react-router";
import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { batch } from "react-redux";
import { Dispatch } from "redux";

import { getProfile } from "../api";
import { User } from "../api/types";
import paths from "../app/paths";
import { RootState } from "../app/rootReducer";
import { loginSuccess, logout, verifyRequest, verifySuccess } from "../features/auth/authSlice";
import { clearProfile, updateProfile } from "../features/profile/profileSlice";
import { myFirebase } from "../libs/firebase";
import { CustomWindow } from "../types";

const myWindow: CustomWindow = window;

export const verifyAuth = () => (dispatch: Dispatch, getState: () => RootState) => {
	dispatch(verifyRequest());
	myFirebase.auth().onAuthStateChanged(async (user) => {
		if (user !== null) {
			const accessToken = await user.getIdToken();
			Cookies.set("accessToken", accessToken);

			LogRocket.identify((user as User).uid, {
				name: (user as User).displayName,
				email: (user as User).email,
			});

			batch(() => {
				dispatch(loginSuccess({ user }));
				dispatch(verifySuccess());
				dispatch(push(paths.HOME.path));
			});
		} else if (getState().auth.isAuthenticated) {
			batch(() => {
				dispatch(clearProfile());
				dispatch(logout());
				dispatch(push(paths.LOGIN.path));
			});
		}
	});
};

export const logOut = () => {
	myFirebase.auth().signOut();
};

export const fetchProfile = () => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
	const profile = await getProfile();
	dispatch(updateProfile(profile));
};

export const redirect = (location: string) => {
	myWindow.store.dispatch(push(location));
};
