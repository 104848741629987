import React from "react";
import VanillaQR from "vanillaqr/VanillaQR.module";

const QRCode = ({ content }: { content: string }) => {
	const qr = new VanillaQR({
		url: content,
		ecclevel: 4,
		size: 150,
	});

	let src = null;
	try {
		src = qr.domElement.toDataURL("png");
	} catch (e) {
		console.log(e);
	}

	return src ? <img src={src} /> : null;
};

export default QRCode;
