import format from "date-fns/format";
import { Dispatch } from "redux";

import { createApiClient } from "../libs/createHttp";
import { myFirebase } from "../libs/firebase";
import { Profile, Receipt, ReportResponse, TotalResponse } from "./types";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

const http = createApiClient();

const requestLogin = () => {
	return {
		type: LOGIN_REQUEST,
	};
};

const loginError = () => {
	return {
		type: LOGIN_FAILURE,
	};
};

export async function signIn(email: string, uid: string): Promise<Profile> {
	return http.post<Profile>("/signin", {
		email,
		uid,
	});
}

export const loginUser = (email: string, password: string) => (dispatch: Dispatch) => {
	dispatch(requestLogin());
	myFirebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.then((user) => {
			//dispatch(loginSuccess(user));
		})
		.catch((error) => {
			//Do something with the error if you want!
			dispatch(loginError());
		});
};

export async function getProfile(): Promise<Profile> {
	return http.get<Profile>("/profile");
}

export async function addReceipt(collectionId: string, receiptId: string): Promise<Receipt> {
	return http.post<Receipt>(`/receipts/collections/${collectionId}`, {
		receiptId,
	});
}

export async function verifyReceipt(id: string): Promise<Receipt> {
	return http.get<Receipt>(`/receipts/verify/${id}`);
}

export async function deleteReceipt(id: string): Promise<Receipt> {
	return http.delete<Receipt>(`/receipts/${id}`);
}

export async function getReport(year: number, month?: number, day?: number): Promise<Receipt> {
	return http.get<Receipt>(`/reports/${year}${month ? `/${month}` : ""}${day ? `/${day}` : ""}`);
}

export async function getReportTotal(from: Date, to: Date): Promise<TotalResponse> {
	const params = {
		from: format(from, "yyyy-MM-dd"),
		to: format(to, "yyyy-MM-dd"),
	} as any;

	return http.get<TotalResponse>(`/reports/total?${new URLSearchParams(params).toString()}`);
}

export async function getReceipts(from: Date, to: Date, limit = 20, startAfter?: string): Promise<ReportResponse> {
	let params = {
		from: format(from, "yyyy-MM-dd"),
		to: format(to, "yyyy-MM-dd"),
		limit: limit.toString(),
	} as any;

	if (startAfter) {
		params = { ...params, startAfter };
	}

	return http.get<ReportResponse>(`/reports?${new URLSearchParams(params).toString()}`);
}
