import path from "../libs/path";

const paths = {
	HOME: path("/"),
	LOGIN: path("/login"),
	REGISTER: path("/register"),
	RECEIPTS: path("/receipts"),
	RECEIPT: path<{ id: number }>("/receipts/:id"),
};

export default paths;
