import { _ } from "@bazo/js-translator";
import React from "react";

import { Lang } from "../types";
import AppStoreDownload from "./appStoreDownload";
import IconFacebook from "./iconFacebook";
import IconInstagram from "./iconInstagram";
import Navbar from "./navbar";

const lang = Lang.SK;

const Layout = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<Navbar />

			<main className="container clearfix">{children}</main>

			<footer>
				<div className="container">
					<div className="clearfix">
						<div className="float-left">
							<AppStoreDownload />
							<div className="contact">
								<p>{_("footer.contact")}</p>
								<a href="mailto:ulozdoklad@gmail.com">ulozdoklad@gmail.com</a>
							</div>
						</div>

						<div className="float-right">
							<IconFacebook />
							&nbsp;
							<IconInstagram />
						</div>
					</div>

					<div className="clearfix links">
						<nav className="inline-block">
							<a href="https://ulozdoklad.com/privacy-policy/" target="_blank" rel="noreferrer noopener">
								{_("links.privacyPolicy")}
							</a>
							&nbsp;
							<a
								href="https://ulozdoklad.com/terms-and-conditions/"
								target="_blank"
								rel="noreferrer noopener"
							>
								{_("links.termsAndConditions")}
							</a>
						</nav>
						<span className="float-right inline-block company-address">
							© 2020 BAZIK, s.r.o. · Tallerova 9 · 81102 Bratislava ·{" "}
							{lang === Lang.SK ? "Slovensko" : "Slovakia"}
						</span>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Layout;
