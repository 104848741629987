import "../styles/modal.scss";

import React, { MouseEvent, ReactChild, ReactChildren, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

export interface ModalProps {
	title: string;
	children: ReactChildren | ReactChild | Element | Element[] | JSX.Element | JSX.Element[];
	extraClass?: string;
	onCloseClicked: (event: MouseEvent<HTMLElement>) => void;
}

export const Modal = ({ title, children, extraClass, onCloseClicked }: ModalProps) => {
	const el = document.createElement("div");
	const modalRoot = document.getElementById("modal-root");

	useLayoutEffect(() => {
		modalRoot.appendChild(el);

		return () => {
			modalRoot.removeChild(el);
		};
	}, [el, modalRoot]);

	return createPortal(
		[
			<div className={`modal ${extraClass ? extraClass : ""}`} key="modal">
				<div className="modal-dialog">
					<div className="modal-header">
						<h5 className="modal-title">{title}</h5>
						<div className="close" onClick={onCloseClicked}>
							<i className="fas fa-times"></i>
						</div>
					</div>
					<div className="modal-body">{children}</div>
				</div>
				<div className="overlay" key="overlay" onClick={onCloseClicked} />
			</div>,
		],
		el
	);
};
