import React from "react";

interface Props {
	mode?: "dark" | "light";
}

export default function AppStoreDownload({ mode = "dark" }: Props) {
	return (
		<a href="https://apps.apple.com/sk/app/uloz-doklad/id1505615761" target="_blank" rel="noopener noreferrer">
			<img
				className="appStore"
				src={`/${mode === "light" ? "appStore" : "appStoreWhite"}.svg`}
				alt="Uloz doklad"
			/>
		</a>
	);
}
