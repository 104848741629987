import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "../../api/types";

export const initialState = {
	isLoggingIn: true,
	isLoggingOut: false,
	isVerifying: true,
	loginError: false,
	logoutError: false,
	isAuthenticated: false,
	user: null as User,
};

export type AuthState = typeof initialState;

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginSuccess(state, action: PayloadAction<{ user: User }>) {
			const { uid, displayName, photoURL, email } = action.payload.user;
			const user = { uid, displayName, photoURL, email };
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: true,
				user,
			};
		},
		loginFailure(state) {
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: false,
				loginError: true,
			};
		},
		verifyRequest(state) {
			return {
				...state,
				isVerifying: true,
				verifyingError: false,
			};
		},
		verifySuccess(state) {
			return {
				...state,
				isVerifying: false,
			};
		},
		logout(state) {
			return initialState;
		},
	},
});

export const { loginFailure, loginSuccess, verifyRequest, verifySuccess, logout } = authSlice.actions;

export default authSlice.reducer;
