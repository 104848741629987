import "../../components/qrReader/qrReader.scss";

import { _ } from "@bazo/js-translator";
import React, { useEffect, useRef, useState } from "react";
import { Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { addReceipt, verifyReceipt } from "../../api";
import { Receipt, User } from "../../api/types";
import { Modal } from "../../components/modal";
import QrReader from "../../components/qrReader";
import ReceiptPreview from "./receiptPreview";

interface Props {
	onCloseClicked: () => void;
	onScanned: (receipt: Receipt) => void;
	user: User;
}

const AddReceiptForm = ({ onCloseClicked, onScanned, user }: Props) => {
	const [data, setData] = useState<Receipt>(null);
	const [cameraEnabled, setCameraEnabled] = useState(true);
	const idInput = useRef<HTMLInputElement>();

	const [scan$, setScanStream] = useState(null as Subject<string>);

	useEffect(() => {
		const scan$ = new Subject<string>();

		const subscription = scan$.pipe(distinctUntilChanged()).subscribe((id) => {
			verifyReceipt(id).then(setData);
		});

		setScanStream(scan$);

		return () => subscription.unsubscribe;
	}, []);

	const handleScan = (id: string) => {
		if (id) {
			scan$.next(id);
		}
	};
	const handleError = (err) => {
		console.error(err);
		setCameraEnabled(false);
	};

	const add = async () => {
		try {
			const receipt = await addReceipt("default", data.ID);
			onScanned(receipt);
		} catch (e) {
			console.log(e);
		}
	};

	const verify = async () => {
		const res = await verifyReceipt(idInput.current.value.toUpperCase());
		console.log(res);
	};

	const alreadyScanned = data?.userID === user.uid;

	return (
		<Modal title={_("receiptScanner.title")} onCloseClicked={onCloseClicked} extraClass="receipt-scanner">
			<div className="add-receipt-form">
				<div className="scanner">
					{_("receiptScanner.scan")}
					{cameraEnabled && <QrReader delay={300} onError={handleError} onScan={handleScan} mirrorVideo />}
					{_("receiptScanner.enterManually")}
					<div className="receipt-manual">
						<input type="text" ref={idInput} />
						<button onClick={verify}>{_("receiptScanner.btnVerify")}</button>
					</div>
				</div>
				<div className="preview">
					{data && (
						<button onClick={add} disabled={alreadyScanned}>
							{alreadyScanned ? _("receiptScanner.alreadyScanned") : _("receiptScanner.btnAdd")}
						</button>
					)}
					<ReceiptPreview receipt={data} showEmpty />
				</div>
			</div>
		</Modal>
	);
};

export default AddReceiptForm;
