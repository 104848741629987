import { Action, configureStore, getDefaultMiddleware, ThunkAction } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { Store, StoreEnhancer } from "redux";
import logger from "redux-logger";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	Persistor,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { verifyAuth } from "../actions";
import monitorReducerEnhancer from "../store/enhancers/monitor";
import createRootReducer, { RootState } from "./rootReducer";

const persistConfig = (key: string) => {
	return {
		key,
		storage,
		whitelist: ["auth", "profile"],
		blacklist: ["user"],
	};
};

//export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default (history: History): { store: Store<RootState>; persistor: Persistor } => {
	const middleware = [routerMiddleware(history)];

	if (process.env.NODE_ENV === "development") {
		middleware.push(logger);
	}

	const rootReducer = createRootReducer(history);
	const persistedReducer = persistReducer(persistConfig("root"), rootReducer);

	const store = configureStore({
		reducer: persistedReducer,
		middleware: [
			...getDefaultMiddleware({
				immutableCheck: false,
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
					ignoredActionPaths: ["payload.user"],
					// Ignore these paths in the state
					ignoredPaths: [],
				},
			}),
			...middleware,
		],
		enhancers: (defaultEnhancers) => [monitorReducerEnhancer as StoreEnhancer, ...defaultEnhancers],
	});

	verifyAuth()(store.dispatch, store.getState);

	const persistor = persistStore(store);
	return { store, persistor };
};
