import { History } from "history";
import React from "react";
import { Redirect, Route } from "react-router";

import { RootState } from "../app/rootReducer";
import withRedux from "../withRedux";

type Props = {
	authenticated: boolean;
	history: History;
	tokenExpires: number;
	match: any;
};

function mapStateToProps({ auth, /*profile,*/ router }: RootState) {
	return { ...auth };
}

export function PrivateRoute(ComposedComponent: React.ComponentType) {
	const Secured = ({ isAuthenticated, ...props }: { isAuthenticated: boolean }) => {
		return (
			<Route
				{...props}
				render={({ location }) =>
					isAuthenticated ? (
						<ComposedComponent {...props} />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	};
	return withRedux({}, mapStateToProps)(Secured);
}
